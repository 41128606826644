import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

// Components
import List from "../custom-widgets/list";

// Helpers
import useScrollOffset from "../../helpers/useScrollOffset";

const SavingsCard = (props) => {
  const {
    id,
    image: { imgVariable, altText } = {},
    title,
    subtitle,
    bodyTitle,
    checkMarksData,
    link: { id: linkId, url, class: linkClass = "btn-primary" },
    btnText,
    disclaimer
  } = props;
  return (
    <div id={id} className="card shadow border-radius-12 h-100">
      {imgVariable && (
        <GatsbyImage className={`card-image-top border-radius-bottom-0`} image={imgVariable} alt={altText || ""} />
      )}
      <div className="card-body d-flex flex-column align-items-start">
        {!subtitle ? (
          <h3>{title}</h3>
        ) : (
          <>
            <h2>{title}</h2>
            <h4 className="my-3 font-weight-bold text-success">{subtitle}</h4>
          </>
        )}
        {bodyTitle && <p className="font-weight-bold mt-3 mb-4">{bodyTitle}</p>}
        <div className="mb-2">
          <List {...checkMarksData} />
        </div>
        <Link id={linkId} className={`btn w-100 mb-2 mt-auto ${linkClass}`} to={url}>
          {btnText}
        </Link>
        <p
          className="text-sm text-muted mt-1 mb-0"
          id={`${title.toLowerCase().replace(/ +/g, "-")}-disclaimer`}
          dangerouslySetInnerHTML={{ __html: disclaimer }}
        />
      </div>
    </div>
  );
};

const SavingsOverview = (props) => {
  const { isSpanish } = props;

  const imgData = useStaticQuery(graphql`
    {
      startSavings: file(relativePath: { eq: "cards/thumbnail-start-saving-080124.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      savings: file(relativePath: { eq: "cards/thumbnail-saving-080124.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      highYield: file(relativePath: { eq: "cards/card-money-market.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      goldMoneyMarket: file(relativePath: { eq: "cards/thumbnail-money-market-110723.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
      moneyMarket: file(relativePath: { eq: "cards/thumbnail-money-market-022324.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  `);

  // use the scroll offset (based upon the height of the sticky header) to pad the scrolling correctly.
  const scrollOffset = useScrollOffset();

  const savingsCardData = [
    {
      id: 1,
      title: "Start Savings",
      subtitle: "Designed to help you save and earn more for doing it",
      bodyTitle: "Save for your future self:",
      liClass: "col-lg-6",
      checkMarksData: {
        greenCircleCheckmark: true,
        accountName: "start-savings",
        items: [
          {
            id: 1,
            text: "Only $25 initial deposit"
          },
          {
            id: 2,
            text: "No minimum balance requirements"
          },
          {
            id: 3,
            text: "No monthly service charge"
          },
          {
            id: 4,
            text: `<a href="/personal-banking/interest-rates" id="interest-rates-page-link">Variable blended rate</a>`
          },
          {
            id: 5,
            text: "Must have a WaFd Checking account to open"
          }
        ]
      },
      image: {
        imgVariable: imgData.startSavings.childImageSharp.gatsbyImageData,
        altText: "Young man relaxing while working on his laptop at home."
      },
      link: {
        id: "start-savings-open-account-cta",
        url: "/open-an-account"
      }
    },
    {
      id: 2,
      title: "Savings",
      subtitle: "Open a secure savings account to set aside and grow funds for your future",
      bodyTitle: "Everything you need in a Savings account:",
      liClass: "col-lg-6",
      checkMarksData: {
        greenCircleCheckmark: true,
        accountName: "savings",
        items: [
          {
            id: 1,
            text: "Free for minor accounts up to age 18"
          },
          {
            id: 2,
            text: "Only $25 initial deposit ($10 for minors)"
          },
          {
            id: 3,
            text: "$3 monthly fee waived on balances above $100"
          },
          {
            id: 4,
            text: 'Minimum balance to earn interest is $100. ($10 for minors); this is a variable-rate account <a id="savings-interest-rates-link" href="/personal-banking/interest-rates">See interest rates/APY</a>'
          }
        ]
      },
      image: {
        imgVariable: imgData.savings.childImageSharp.gatsbyImageData,
        altText: "Father and son laughing under an umbrella on a rainy day."
      },
      link: {
        id: "savings-open-account-cta",
        url: "/open-an-account"
      }
    },
    {
      id: 3,
      title: "High Yield Money Market",
      checkMarksData: {
        greenCircleCheckmark: true,
        accountName: "high-yield-money-market",
        items: [
          {
            id: 1,
            text: "Only $25 initial deposit"
          },
          {
            id: 2,
            text: "$10 monthly fee waived on balances above $1,000 (Not available for IRAs or Business Clients)"
          },
          {
            id: 3,
            text: 'This is a variable-rate account <a id="high-yield-interest-rates-link" href="/personal-banking/interest-rates">See interest rates/APY</a>'
          },
          {
            id: 4,
            text: "Product Effective 10/19/2022. Promotion may be discontinued at any time"
          }
        ]
      },
      image: {
        imgVariable: imgData.highYield.childImageSharp.gatsbyImageData,
        altText: "Grandfather and granddaughter counting money with piggy bank."
      },
      link: {
        id: "high-yield-open-account-cta",
        url: "/open-an-account"
      }
    },
    {
      id: 4,
      title: "Gold Money Market",
      checkMarksData: {
        greenCircleCheckmark: true,
        accountName: "gold-money-market",
        items: [
          {
            id: 1,
            text: "Only $25 initial deposit"
          },
          {
            id: 2,
            text: "$10 monthly fee waived on balances above $1,000 (Not available for IRAs)"
          },
          {
            id: 3,
            text: 'Minimum to earn interest is $1,000; this is a variable-rate account <a id="gold-money-market-interest-rates-link" href="/personal-banking/interest-rates">See interest rates/APY</a>'
          }
        ]
      },
      image: {
        imgVariable: imgData.goldMoneyMarket.childImageSharp.gatsbyImageData,
        altText: "Couple looking at phone while cooking."
      },
      link: {
        id: "gold-open-account-cta",
        url: "/open-an-account"
      }
    },
    {
      id: 5,
      title: "Money Market",
      checkMarksData: {
        greenCircleCheckmark: true,
        accountName: "money-market",
        items: [
          {
            id: 1,
            text: "Only $25 initial deposit"
          },
          {
            id: 2,
            text: "$10 monthly fee waived on balances above $1,000"
          },
          {
            id: 3,
            text: 'Minimum to earn interest is $1,000; this is a variable-rate account <a id="money-market-interest-rates-link" href="/personal-banking/interest-rates">See interest rates/APY</a>'
          }
        ]
      },
      image: {
        imgVariable: imgData.moneyMarket.childImageSharp.gatsbyImageData,
        altText: "Couple laughing sitting on sofa in living room."
      },
      link: {
        id: "money-market-open-account-cta",
        url: "/open-an-account"
      }
    }
  ];

  const savingsCardDataSpanish = [
    {
      id: 1,
      title: "Start Savings",
      subtitle: "Diseñada para ayudarte a ahorrar y ganar más dinero al hacerlo.",
      bodyTitle: "Ahorra para tu yo futuro:",
      liClass: "col-lg-6",
      checkMarksData: {
        greenCircleCheckmark: true,
        accountName: "start-savings",
        items: [
          {
            id: 1,
            text: "Solo $25 de depósito inicial"
          },
          {
            id: 2,
            text: "Sin requisitos de saldo mínimo"
          },
          {
            id: 3,
            text: "Sin cuotas mensuales de mantenimiento"
          },
          {
            id: 4,
            text: `<a href="/es/banca-personal/tasas-de-interes" id="tasas-de-interes-page-link">Tasa mixta variable</a>`
          },
          {
            id: 5,
            text: "Debes tener una cuenta corriente de WaFd para abrirla"
          }
        ]
      },
      image: {
        imgVariable: imgData.startSavings.childImageSharp.gatsbyImageData,
        altText: "Hombre joven relajándose mientras trabaja en su portátil en casa."
      },
      link: {
        id: "start-savings-open-account-cta",
        url: "/open-an-account"
      }
    },
    {
      id: 2,
      title: "Ahorros",
      subtitle:
        "Abra una cuenta de ahorros con las herramientas y servicios para ayudarle a prepararse para el futuro.",
      bodyTitle: "Todo lo que necesitas en una cuenta de Ahorro:",
      liClass: "col-lg-6",
      checkMarksData: {
        greenCircleCheckmark: true,
        accountName: "savings",
        items: [
          {
            id: 1,
            text: "Cuentas gratis para menores de hasta 18 años"
          },
          {
            id: 2,
            text: "Depósito inicial de $25 ($10 para menores de edad)"
          },
          {
            id: 3,
            text: "La tarifa de $3 mensuales se exenta en saldos superiores a $100"
          },
          {
            id: 4,
            text: 'El saldo mínimo para ganar intereses es $100. ($10 para menores); esta es una cuenta de tasa variable. <a id="savings-interest-rates-cta" href="/es/banca-personal/tasas-de-interes">Ver tasas de interés / APY</a>'
          }
        ]
      },
      image: {
        imgVariable: imgData.savings.childImageSharp.gatsbyImageData,
        altText: "Padre e hijo riendo bajo un paraguas en un día lluvioso."
      },
      link: {
        id: "savings-open-account-cta",
        url: "/open-an-account"
      }
    },
    {
      id: 3,
      title: "Cuentas Money Market de Interés Alto",
      checkMarksData: {
        greenCircleCheckmark: true,
        accountName: "high-yield-money-market",
        items: [
          {
            id: 1,
            text: "Depósito inicial de solo $25"
          },
          {
            id: 2,
            text: "La tarifa de $10 mensuales se exenta en saldos superiores a $1,000 (No disponible para IRA o clientes comerciales)"
          },
          {
            id: 3,
            text: "Producto a partir del 10/19/2022 la promocion puede suspenderse en cualquier momento"
          },
          {
            id: 4,
            text: 'El saldo minimo para ganar intereses es de $1,000; esta es una cuenta de tasa variable. <a id="money-market-interest-rates-cta" href="/es/banca-personal/tasas-de-interes">Ver tasas de interés / APY</a>'
          }
        ]
      },
      image: {
        imgVariable: imgData.highYield.childImageSharp.gatsbyImageData,
        altText: "Abuelo y su nieta contando el dinero de una alcancía de puerquito."
      },
      link: {
        id: "high-yield-open-account-cta",
        url: "/open-an-account"
      }
    },
    {
      id: 4,
      title: "Money Market de Oro (Gold Money Market)",
      checkMarksData: {
        greenCircleCheckmark: true,
        accountName: "gold-money-market",
        items: [
          {
            id: 1,
            text: "Depósito inicial de solo $25"
          },
          {
            id: 2,
            text: "La tarifa de $10 mensuales se exenta en saldos superiores a $1,000 (No disponible para IRA)"
          },
          {
            id: 3,
            text: 'El saldo minimo para ganar intereses es de $1,000. Gane intereses a una tasa más alta de interés con una cuenta Gold Money Market. Esta es una cuenta de tasa variable. <a id="gold-money-market-interest-rates-cta" href="/es/banca-personal/tasas-de-interes">Ver tasas de interés / APY</a>'
          }
        ]
      },
      image: {
        imgVariable: imgData.goldMoneyMarket.childImageSharp.gatsbyImageData,
        altText: "Pareja mirando el teléfono mientras cocinan."
      },
      link: {
        id: "gold-open-account-cta",
        url: "/open-an-account"
      }
    },
    {
      id: 5,
      title: "Money Market",
      checkMarksData: {
        greenCircleCheckmark: true,
        accountName: "money-market",
        items: [
          {
            id: 1,
            text: "Depósito inicial de solo $25"
          },
          {
            id: 2,
            text: "La tarifa de $10 mensuales se exenta en saldos superiores a $1,000"
          },
          {
            id: 3,
            text: 'El saldo minimo para ganar intereses es de $1,000; esta es una cuenta de tasa variable. <a id="money-market-interest-rates-cta" href="/es/banca-personal/tasas-de-interes">Ver tasas de interés / APY</a>'
          }
        ]
      },
      image: {
        imgVariable: imgData.moneyMarket.childImageSharp.gatsbyImageData,
        altText: "Pareja riendo sentada en el sofá de la sala de estar."
      },
      link: {
        id: "money-market-open-account-cta",
        url: "/open-an-account"
      }
    }
  ];

  // Function that returns language dictionary, it receives isSpanish boolean
  const getTranslations = (isSpanish) => {
    const englishDict = {
      savingsOverviewData: savingsCardData,
      savingsCardBtnText: "Open an Account",
      savingsCardDisclaimer: "WaFd Bank will not run your credit for this application.<sup>1</sup>",
      savingsOverviewDisclaimer:
        "<sup>1</sup>For account opening WaFd Bank does not pull your credit from one of the three main credit bureaus. We will review OFAC and ChexSystems<sup>&reg;</sup>."
    };
    const spanishDict = {
      savingsOverviewData: savingsCardDataSpanish,
      savingsCardBtnText: "Abrir una cuenta",
      savingsCardDisclaimer: "WaFd Bank no pide su reporte crediticio para esta aplicación.<sup>1</sup>",
      savingsOverviewDisclaimer:
        "<sup>1</sup>Para abrir una cuenta, WaFd Bank no pide su reporte crediticio de ninguna de las tres agencias de crédito. Revisaremos OFAC y ChexSystems<sup>&reg;</sup>."
    };
    return isSpanish ? spanishDict : englishDict;
  };

  // Deconstruct translation dictionary to be passed to the render components as props.
  const { savingsOverviewData, savingsCardBtnText, savingsCardDisclaimer, savingsOverviewDisclaimer } =
    getTranslations(isSpanish);

  return (
    <section id="savings-overview-section" className="container" style={{ scrollMarginTop: scrollOffset }}>
      <div>
        <ul className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 list-unstyled mb-0">
          {savingsOverviewData.map((savingCard) => (
            <li className={`col mb-4 ${savingCard.liClass}`} key={savingCard.id}>
              <SavingsCard {...savingCard} btnText={savingsCardBtnText} disclaimer={savingsCardDisclaimer} />
            </li>
          ))}
        </ul>
      </div>
      <p
        className="m-0 text-muted"
        dangerouslySetInnerHTML={{ __html: savingsOverviewDisclaimer }}
        id="wafd-accounts-disclaimer"
      />
    </section>
  );
};
export default SavingsOverview;

SavingsOverview.defaultProps = {
  isSpanish: false // Boolean to toggle translation dictionary as required
};

